
import { accessToken, companyId, isExternalExpert } from '@/services/cookies';
import { getDomainForCookies } from '@/helpers/url';
import { tsxassApi } from '@/services/api';

export default {
  name: 'ExpertRegistration',

  inject: ['RouteNames'],

  async created() {
    const { data } = await tsxassApi.postV1Invitations({ token: (this.$route.query?.token as string) || '' });
    accessToken.set(data.jwt, {
      domain: getDomainForCookies(),
      expires: 365,
    });
    companyId.set(this.$route.query?.company_id, {
      domain: getDomainForCookies(),
      expires: 365,
    });
    isExternalExpert.set(true, {
      domain: getDomainForCookies(),
      expires: 365,
    });
    this.$router.push({
      name: this.RouteNames.R_APP_ASSESSMENT_COUPLES,
      params: { surveyId: this.$route.query?.survey_id as string },
    });
  },
};
